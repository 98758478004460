import "./TableContainer.css";
import { useState, useEffect, useCallback } from "react";
import { Tooltip, MenuItem, Select, Fade, IconButton } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import SearchSuggestions from "./SearchSuggestions";
import useSearch from "../../hooks/useSearch";
import trim0xFromHash from "utils/trim0xFromHash";
import NetworkSelector from "./NetworkSelector";
import Contract from "../Contract/ContractTemplate/ContractTemplate";
import Interpreter from "../Contract/Interpreter";
import ContractInstance from "../Contract/ContractInstance";
import SearchIcon from "assets/Icons/icon-search-default.svg";
import SearchIconBlue from "assets/Icons/icon-search-default_blue.svg";
import AllIcon from "assets/Icons/icon-blockchains-default.svg";
import Check from "assets/Icons/check.svg";
import { BlockFragment, NetworkName } from "generated/graphql";
import TableData from "../TableData";
import useChainQueryParameters from "hooks/useChainQueryParameters";
import { UseNetworkState } from "../../hooks/useNetworkState";
import { ThemeProvider } from "@mui/material/styles";
import {
  TabFormControl,
  SelectIcon,
  MetamaskButton,
  SearchPaper,
  SearchInputBase,
  TabMenuDivider,
  ContainerDivider,
  BlockChainArray,
} from "./CustomizedMui";
import { REACT_APP_ENABLED_NETWORKS } from "conf";
import { theme } from "theme";
import { useGlobalIndex } from "../../hooks/useGlobalIndex";
import { Statistics } from "./Statistics";
import ClearIcon from "@mui/icons-material/Clear";
import { NftItem } from "components/Nfts/NftItem";
import useIpfsCidValidate from "components/Nfts/hooks/useIpfsCidValidate";

type loading = "loading";

type TableContainerProps = {
  cwTxVol: number;
  cwTxs: number;
  l1Txs: number;
  networkFetchSizes: number[];
  setNetworkFetchSizes: (sizes: number[]) => void;
  width: number;
  data: BlockFragment[];
  tabs: (UseNetworkState | undefined | loading)[];
  networkNums: number;
  setRowsize: (sizes: number) => void;
  rowsize: number;
  availableTabs: boolean[];
  activeMetamask: boolean;
  onSetSubscription: (value: boolean) => void;
};

enum SEARCH_RESOURCE_TYPE {
  IPFS_NFT_TYPE = "IPFS_NFT_TYPE",
  CONTRACT_TYPE = "CONTRACT_TYPE",
  INTERPRETER_TYPE = "INTERPRETER_TYPE",
  INSTANCE_TYPE = "INSTANCE_TYPE",
}

const TableContainer = (props: TableContainerProps) => {
  const { validateIpfsCid, scrapeCidFromString } = useIpfsCidValidate();

  const {
    networkFetchSizes,
    setNetworkFetchSizes,
    width,
    data,
    tabs,
    networkNums,
    setRowsize,
    rowsize,
    availableTabs,
    activeMetamask,
    onSetSubscription,
  } = props;
  const [searchParams, setSearchParams] = useSearchParams();
  const { index } = useChainQueryParameters();
  const {
    contractTemplates,
    interpreters,
    suggestions,
    contractInstances,
    findContractInstance,
    findContractTemplate,
  } = useGlobalIndex();

  //state index for tab selection, default 0 (ALL)
  const [tabIndex, setTabIndex] = useState<undefined | number>(index ?? 0);
  //state if search function is active or not
  const [search, setSearch] = useState<any>(false);

  const [resourceType, setResourceType] = useState<SEARCH_RESOURCE_TYPE | null>(null);

  const [displaySearchSuggestions, setDisplaySearchSuggestions] = useState<any>(false);

  //final search block hash
  const [searchExactQuery, setSearchExactQuery] = useState("");
  //state to selection menu display for mobile
  const [selectDisplay, setSelectDisplay] = useState(false);
  //Search input text state
  const [searchQuery, setSearchQuery] = useState("");

  //Search input width state
  const [searchWidth, setWidth] = useState("204px");
  //Search icon state for color change on hover and active search
  const [searchIconState, setSearchIconState] = useState(false);
  //Metamask Account state to store
  const [metamaskAccount, setMetamaskAccount] = useState(null);

  const tabData = tabIndex && tabs[tabIndex - 1] ? tabs[tabIndex - 1] : undefined;
  const enableMetamask = process.env.REACT_APP_METAMASK_ENABLED === "true";

  const searchList = useSearch({
    list: suggestions,
    searchString: searchQuery,
    keys: ["data"],
  });

  useEffect(() => {
    const newSearchParams = new URLSearchParams(searchParams.toString());
    const hash = searchParams.get("search");

    if (!hash) {
      setSearch(false);
      setSearchQuery("");
      setResourceType(null);
      newSearchParams.delete("search");
      setSearchParams(newSearchParams);
    } else if (hash) {
      const isIpfsPath = validateIpfsCid(scrapeCidFromString(hash) || "");

      if (isIpfsPath) {
        setSearchExactQuery(hash);
        setSearchQuery(hash);
        setSearchIconState(true);
        setResourceType(SEARCH_RESOURCE_TYPE.IPFS_NFT_TYPE);
        setSearch(false);
        return;
      }

      const filtered = suggestions.find((suggestion) => {
        const isExactMatch = suggestion.data === searchQuery;
        if (isExactMatch) {
          return suggestion;
        }
      });

      setSearchQuery(hash);
      setSearchExactQuery(trim0xFromHash(hash.trim()));
      setSearchIconState(true);

      let newWidth = String(hash.length * 7 + "px ");
      setWidth(newWidth);

      if (!filtered) {
        setSearch(true);
      } else {
        setSearch(false);
        if (filtered.type === "Contract") {
          setResourceType(SEARCH_RESOURCE_TYPE.CONTRACT_TYPE);
        } else if (filtered.type === "Interpreter") {
          setResourceType(SEARCH_RESOURCE_TYPE.INTERPRETER_TYPE);
        } else if (filtered.type === "ContractInstance") {
          setResourceType(SEARCH_RESOURCE_TYPE.INSTANCE_TYPE);
        }
      }
    }
  }, [
    searchQuery,
    searchParams,
    suggestions,
    interpreters,
    contractTemplates,
    contractInstances,
    setSearchParams,
    setSearchExactQuery,
  ]);

  const handleToggle = () => {
    const newSearchParams = new URLSearchParams(searchParams.toString());
    const blockHash: string = trim0xFromHash(searchQuery);
    setDisplaySearchSuggestions(false);
    if (blockHash !== "") {
      setSearchExactQuery(blockHash.trim());
      setSearch(true);
      setSearchIconState(true);
      newSearchParams.set("search", blockHash);
      setSearchParams(newSearchParams);
    } else {
      newSearchParams.delete("search");
      setSearch(false);
      setTabIndex(0);
      if (searchParams.get("chain")) newSearchParams.delete("chain");
      setSearchParams(newSearchParams);
    }
  };
  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const newSearchParams = new URLSearchParams(searchParams.toString());
    const length = e.target.value.length;
    const newWidth = length ? String(length * 7 + "px ") : "204px";
    setWidth(newWidth);
    setSearchQuery(e.target.value);
    setSearchIconState(true);
    setDisplaySearchSuggestions(true);
    newSearchParams.set("search", e.target.value);
    setSearchParams(newSearchParams);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleToggle();
    }
  };
  // CallBack Function to set rowsize from other tabs
  const handleSize = useCallback(() => {
    if (tabIndex === 0) {
      const newSize = rowsize + 10;
      setRowsize(newSize);
    } else {
      let sizes = [...networkFetchSizes]; // create the copy of state array
      if (tabIndex && sizes[tabIndex - 1] + 10 <= 40) sizes[tabIndex - 1] = sizes[tabIndex - 1] + 10; //new value
      setNetworkFetchSizes(sizes);
    }
  }, [setNetworkFetchSizes, tabIndex, networkFetchSizes, rowsize, setRowsize]);

  // CallBack Function to highlight found block network after search action
  const handleSearchNetwork = useCallback(
    (network: NetworkName | undefined | 0) => {
      if (!network) setTabIndex(undefined);
      if (network === 0) setTabIndex(0);
      else {
        for (let i = 0; i < REACT_APP_ENABLED_NETWORKS.length; i++) {
          if (REACT_APP_ENABLED_NETWORKS[i] === network) {
            setTabIndex(i + 1);
            const newSearchParams = new URLSearchParams(searchParams.toString());
            newSearchParams.set("chain", String(REACT_APP_ENABLED_NETWORKS[i]));
            setSearchParams(newSearchParams);
          }
        }
      }
    },
    [setTabIndex, setSearchParams, searchParams]
  );

  const handleClick = useCallback(
    (_event: any, index: number) => {
      setTabIndex(index);
      const newSearchParams = new URLSearchParams(searchParams.toString());
      if (REACT_APP_ENABLED_NETWORKS[index - 1])
        newSearchParams.set("chain", String(REACT_APP_ENABLED_NETWORKS[index - 1]));
      else if (index === 0) newSearchParams.delete("chain");

      setSearch(false);
      setRowsize(10);
      let sizes = new Array(REACT_APP_ENABLED_NETWORKS.length).fill(10);
      setNetworkFetchSizes(sizes);
      if (index === 0) newSearchParams.delete("search");
      setSearchParams(newSearchParams);
    },
    [setTabIndex, setSearch, setNetworkFetchSizes, setRowsize, setSearchParams, searchParams]
  );

  // Function to handle Blockchain Network Tabs onClick
  const handleTabClick = useCallback(
    (event: any, index: number) => {
      setTabIndex(index);
      const newSearchParams = new URLSearchParams(searchParams.toString());
      if (REACT_APP_ENABLED_NETWORKS[index - 1]) {
        newSearchParams.set("chain", String(REACT_APP_ENABLED_NETWORKS[index - 1]));
      } else if (index === 0) newSearchParams.delete("chain");

      setSearch(false);
      setSearchIconState(false);
      setRowsize(10);
      setSearchQuery("");
      let sizes = new Array(REACT_APP_ENABLED_NETWORKS.length).fill(10);
      setNetworkFetchSizes(sizes);
      setWidth("204px");
      if (index === 0) newSearchParams.delete("search");
      setSearchParams(newSearchParams);
    },
    [setTabIndex, searchParams, setSearchParams, setSearch, setSearchIconState, setNetworkFetchSizes, setRowsize]
  );

  async function loginWithMetaMask() {
    if (!metamaskAccount) {
      const accounts = await (window as any).ethereum.request({ method: "eth_requestAccounts" }).catch((e: any) => {
        console.error(e.message);
        return;
      });
      if (!accounts) {
        return;
      }

      (window as any).userWalletAddress = accounts[0];
      // userWallet.innerText = (window as any).userWalletAddress
      setMetamaskAccount(accounts[0]);
    } else {
      return;
    }
  }

  const onClear = useCallback(() => {
    const newSearchParams = new URLSearchParams(searchParams.toString());
    newSearchParams.delete("search");
    setSearchParams(newSearchParams);
    setWidth("204px");
  }, [searchParams, setSearchParams]);

  const contractCondition = resourceType === SEARCH_RESOURCE_TYPE.CONTRACT_TYPE && findContractTemplate(searchQuery);

  const interpreterCondition = resourceType === SEARCH_RESOURCE_TYPE.INTERPRETER_TYPE && interpreters;

  const contractInstanceCondition =
    resourceType === SEARCH_RESOURCE_TYPE.INSTANCE_TYPE && findContractInstance(searchQuery);

  const ipfsNftCondition = resourceType === SEARCH_RESOURCE_TYPE.IPFS_NFT_TYPE;

  return (
    <div id={width < 1050 ? "Table-outer-container-mobile" : "Table-outer-container"}>
      <div id="Table-container">
        {width > 600 ? (
          <div id="Upper-data-table">
            <div id="Counter-outer-container">
              <Statistics {...props} />
            </div>
            {activeMetamask && enableMetamask ? (
              <div id="Metamask-container">
                <MetamaskButton
                  type="button"
                  // id="Button-mobile"
                  onClick={() => {
                    loginWithMetaMask();
                  }}
                  variant="contained"
                >
                  {metamaskAccount ? "CONNECTED" : "CONNECT METAMASK"}
                </MetamaskButton>
                {metamaskAccount ? (
                  <div id="AccountId_container">
                    <p>Account Id: {metamaskAccount}</p>
                  </div>
                ) : null}
              </div>
            ) : null}

            <div id="Search-container">
              <div id="Search">
                <SearchPaper
                  key="Search-Paper"
                  onMouseEnter={() => setSearchIconState(true)}
                  onMouseLeave={() => (!search ? setSearchIconState(false) : null)}
                  onKeyDown={handleKeyDown}
                >
                  <IconButton
                    onClick={handleToggle}
                    sx={{
                      padding: "10px 2px 10px 16px",
                      color: "#d3d3ef !important",
                    }}
                    aria-label="search"
                  >
                    <img inputMode="search" src={searchIconState ? SearchIconBlue : SearchIcon} alt="Search" />
                  </IconButton>

                  <SearchInputBase
                    sx={{ width: searchWidth }}
                    value={searchQuery}
                    id="Search-InputBase"
                    placeholder="Search by Block/L2Tx Hash"
                    inputProps={{ "aria-label": "block hash" }}
                    key="search-inputBase"
                    onChange={(e) => handleChange(e)}
                  />
                  {!!searchQuery && (
                    <IconButton
                      onClick={onClear}
                      sx={{
                        padding: "10px 16px 10px 2px",
                        color: "#d3d3ef !important",
                        transition: "0.3s ease",
                        "&:hover": {
                          opacity: 0.7,
                        },
                      }}
                      aria-label="search"
                    >
                      <ClearIcon />
                    </IconButton>
                  )}
                </SearchPaper>
                {!!searchList.length && searchQuery !== "" && displaySearchSuggestions && (
                  <SearchSuggestions
                    onSuggestionClick={setSearchQuery}
                    suggestions={searchList}
                    onDisplayChange={setDisplaySearchSuggestions}
                  />
                )}
              </div>
            </div>
            {tabIndex !== undefined && (
              <div id="Tab-container">
                <div id="Tabs-container">
                  <div className={`Tab ${tabIndex === 0 ? "Active" : ""}`} onClick={(e) => handleClick(e, 0)}>
                    <div className="Tab-icon-container" style={{ height: width < 900 ? "50px" : "55px" }}>
                      <img className="Tab-icon" src={AllIcon} alt="All"></img>
                    </div>
                    <div>All Chains</div>
                    <div
                      className="Tab-bottom-line"
                      style={{
                        backgroundColor: tabIndex === 0 ? "#00c3ff" : "#4f4f72",
                      }}
                    ></div>
                  </div>
                  {BlockChainArray.map((tab, i) => {
                    return availableTabs[i] ? (
                      <div
                        key={tab.label}
                        className={`Tab ${tabIndex === i + 1 ? "Active" : ""}`}
                        onClick={(e) => handleTabClick(e, i + 1)}
                      >
                        <div>
                          <Tooltip
                            componentsProps={{
                              tooltip: {
                                sx: {
                                  backgroundColor: "#4f4f72 !important",
                                  padding: "0px !important",
                                  marginTop: "-1px !important",
                                },
                              },
                            }}
                            disableFocusListener
                            TransitionComponent={Fade}
                            title={<div className="Tab-tooltip">{tab.hover}</div>}
                          >
                            <div className="Tab-icon-container">
                              <img className="Tab-icon" src={tab.icon} alt={tab.hover}></img>
                            </div>
                          </Tooltip>
                          <div>{tab.label}</div>
                          <div
                            className="Tab-bottom-line"
                            style={{
                              backgroundColor: tabIndex === i + 1 ? "#00c3ff" : "#4f4f72",
                            }}
                          ></div>
                        </div>
                      </div>
                    ) : null;
                  })}
                </div>
              </div>
            )}
          </div>
        ) : (
          // Search input and Tabs section for Mobile Screens
          <div id="Upper-mobile-data-table">
            <div id="Counter-outer-container-mobile">
              <Statistics {...props} />
            </div>

            <div className="Tab-icon-container" style={{ height: "56px" }}>
              <img
                className="Tab-icon"
                src={!tabIndex ? AllIcon : BlockChainArray[tabIndex - 1].icon}
                alt="All"
                style={{ width: "42px" }}
              />
            </div>

            <div id="Search-container">
              <div id="Search-mobile">
                <SearchPaper
                  onMouseEnter={() => setSearchIconState(true)}
                  onMouseLeave={() => setSearchIconState(false)}
                  onKeyDown={handleKeyDown}
                >
                  <IconButton
                    onClick={handleToggle}
                    sx={{
                      padding: "10px 2px 10px 16px",
                      color: "#d3d3ef !important",
                    }}
                    aria-label="search"
                  >
                    <img inputMode="search" src={searchIconState ? SearchIconBlue : SearchIcon} alt="Search" />
                  </IconButton>

                  <SearchInputBase
                    sx={{
                      width: searchWidth,
                    }}
                    id="Search-InputBase"
                    placeholder="Search by Block/L2Tx Hash"
                    inputProps={{ "aria-label": "block hash" }}
                    value={searchQuery}
                    onChange={(e) => {
                      setSearchQuery(e.target.value);
                      setDisplaySearchSuggestions(true);
                    }}
                  />
                  {!!searchQuery && (
                    <IconButton
                      onClick={onClear}
                      sx={{
                        padding: "10px 16px 10px 2px",
                        color: "#d3d3ef !important",
                        transition: "0.3s ease",
                        "&:hover": {
                          opacity: 0.7,
                        },
                      }}
                      aria-label="search"
                    >
                      <ClearIcon />
                    </IconButton>
                  )}
                </SearchPaper>
                {!!searchList.length && searchQuery !== "" && displaySearchSuggestions && (
                  <SearchSuggestions
                    onSuggestionClick={setSearchQuery}
                    suggestions={searchList}
                    onDisplayChange={setDisplaySearchSuggestions}
                  />
                )}
              </div>
            </div>

            {tabIndex !== undefined && (
              <div>
                <TabFormControl>
                  <ThemeProvider theme={theme}>
                    <Select
                      open={selectDisplay}
                      value={tabIndex}
                      onChange={(e) => handleTabClick(e, e.target.value as unknown as number)}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                      defaultValue={0}
                      IconComponent={SelectIcon}
                      onOpen={() => setSelectDisplay(true)}
                      onClose={() => setSelectDisplay(false)}
                      sx={{
                        ".MuiSelect-icon": {
                          top: "17px", // Adjust the icon's top position
                        },
                      }}
                    >
                      <MenuItem key="All Chain" value={0}>
                        {selectDisplay ? (
                          <div className="Select-item-wrapper">
                            <div className="Select-item-container">
                              <div className="Select-icon-container">
                                <img style={{ width: "29px" }} src={AllIcon} alt="All"></img>
                              </div>
                              <div
                                className="Select-value"
                                style={{
                                  color: tabIndex === 0 ? "#ffffff" : undefined,
                                }}
                              >
                                All Chains
                              </div>
                              {tabIndex === 0 && (
                                <div
                                  style={{
                                    marginLeft: "auto",
                                    alignSelf: "center",
                                  }}
                                >
                                  <div className="Select-icon-selected">
                                    <img style={{ width: "17px" }} src={Check} alt="check"></img>
                                  </div>
                                </div>
                              )}
                            </div>
                            <div id="Select-divider">
                              <TabMenuDivider variant="middle" />
                            </div>
                          </div>
                        ) : (
                          <div className="Select-value" style={{ color: "#fff" }}>
                            All Chains
                          </div>
                        )}
                      </MenuItem>

                      {BlockChainArray.map((tab, index) => {
                        return tabs[index] ? (
                          <MenuItem key={tab.label} value={index + 1}>
                            {selectDisplay ? (
                              <div className="Select-item-wrapper">
                                <div className="Select-item-container">
                                  <div className="Select-icon-container">
                                    <img width={29} src={tab.icon} alt={tab.hover}></img>
                                  </div>
                                  <div
                                    className="Select-value"
                                    style={{
                                      color: index === index + 1 ? "#ffffff" : undefined,
                                    }}
                                  >
                                    {tab.hover} - {tab.label}
                                  </div>
                                  {index === index + 1 ? (
                                    <div
                                      style={{
                                        marginLeft: "auto",
                                        alignSelf: "center",
                                      }}
                                    >
                                      <div className="Select-icon-selected">
                                        <img style={{ width: "17px" }} src={Check} alt="check"></img>
                                      </div>
                                    </div>
                                  ) : null}
                                </div>
                                {index === networkNums - 1 ? null : (
                                  <div id="Select-divider">
                                    <TabMenuDivider variant="middle" />
                                  </div>
                                )}
                              </div>
                            ) : (
                              <div key={tab.label} className="Select-value" style={{ color: "#fff" }}>
                                {tab.hover} - {tab.label}
                              </div>
                            )}
                          </MenuItem>
                        ) : null;
                      })}
                    </Select>
                  </ThemeProvider>
                </TabFormControl>
              </div>
            )}
            <ContainerDivider variant="middle" />
          </div>
        )}

        {search === true && tabData !== "loading" && (
          <div id="Block-data-table">
            <NetworkSelector
              setNetwork={handleSearchNetwork}
              hash={searchExactQuery}
              width={width}
              onSetSubscription={onSetSubscription}
            />
          </div>
        )}

        {tabData !== "loading" && search === false && !resourceType && (
          <div id="Block-data-table">
            <TableData
              data={!tabData ? data : tabData.lastBlocks}
              width={width}
              onSetSubscription={onSetSubscription}
            />
            {data.length > 1 && tabIndex !== 0 && (
              <div id="Loadmore-container">
                <button type="button" id="Loadmore-button" onClick={handleSize}>
                  Load more
                </button>
              </div>
            )}
          </div>
        )}

        {tabData !== "loading" &&
          search === false &&
          (contractCondition ? (
            <Contract width={width} data={contractCondition} />
          ) : interpreterCondition ? (
            <Interpreter width={width} id={searchQuery} data={interpreters[searchQuery]} />
          ) : contractInstanceCondition ? (
            <ContractInstance width={width} data={contractInstanceCondition} />
          ) : ipfsNftCondition ? (
            <NftItem width={width} ipfs={searchQuery} />
          ) : null)}
      </div>
    </div>
  );
};

export default TableContainer;
