import { ClaimKeyFirst, ClaimKeySecond, cweb_api, NetworkName, ClaimIssuer, CwebApi } from "@coinweb/claims-client";
import { REACT_APP_WALLET_API_ENDPOINT } from "conf";
import { useCallback, useRef } from "react";

const useFetchBlocks = () => {
  const cwebClaimsClientRef = useRef<CwebApi | null>(cweb_api(REACT_APP_WALLET_API_ENDPOINT || ""));

  const initRequest = useCallback(async (networkName: NetworkName) => {
    const cwebClaimsClient = cwebClaimsClientRef.current;
    if (!cwebClaimsClient) {
      throw new Error("cwebClaimsClient is not initialized");
    }
    try {
      const shard = cwebClaimsClient.shard(networkName);

      let contract;
      const issuer = "L2BlockInfoProvider" as ClaimIssuer;
      contract = shard.provider(issuer);

      return contract;
    } catch (error) {
      console.error("Error in initRequest: ", error);
      throw error;
    }
  }, []);

  const readClaim = useCallback(
    (shard: NetworkName) => async (secondKey: ClaimKeySecond) => {
      const firstKey: ClaimKeyFirst = "L2BlockIdToHeightFirstPart";
      return await initRequest(shard).then((contract) => contract.get(firstKey, Number(secondKey)));
    },
    [initRequest]
  );

  return { readClaim };
};

export default useFetchBlocks;
