import "./styles.css";
import Typography from "@mui/material/Typography";
import { TargetInstance } from "hooks/useGlobalIndex/types";
import { memo } from "react";
import { useSearchParams } from "react-router-dom";
import { TooltipTx } from "shared/components/TooltipTx";
import { Alias } from "shared/components";
import checkIssuerAlias from "utils/checkIssuerAlias";
import { useGlobalIndex } from "hooks/useGlobalIndex";

type TargetInstancesProps = {
  data: TargetInstance[];
};

const TargetInstances = (props: TargetInstancesProps) => {
  const { data } = props;
  const [searchParams, setSearchParams] = useSearchParams();
  const { contractTemplates, interpreters, contractInstances } = useGlobalIndex();

  return (
    <div>
      {data.map((target, index) => {
        const targetAlias = target?.alias
          ? checkIssuerAlias(target.alias, interpreters, contractTemplates, contractInstances)
          : undefined;

        return (
          <div
            key={index}
            style={{
              width: "100%",
              backgroundColor: "#20214a",
              borderRadius: "6px",
              marginBottom: "20px",
            }}
            className="grid_container"
            onClick={() => {
              const newSearchParams = new URLSearchParams(searchParams.toString());
              newSearchParams.set("search", target?.instance_id);
              setSearchParams(newSearchParams);
            }}
          >
            <div className="grid_cell" style={{ margin: "10px 0px" }}>
              <Typography className="BoldWhiteFont14" align="left">
                Contract Instance Alias
              </Typography>
              <div className="L2TxId-row">
                <Typography className="BoldFont14Hover" align="center">
                  {targetAlias ? <Alias alias={targetAlias} /> : target?.alias}
                </Typography>
              </div>
            </div>
            <div className="grid_cell" style={{ margin: "10px 0px" }}>
              <Typography className="BoldWhiteFont14" align="left">
                Contract Instance Id
              </Typography>
              <div className="L2TxId-row L2TxId-container">
                <TooltipTx value={target?.instance_id} />
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default memo(TargetInstances);
