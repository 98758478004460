import Typography from "@mui/material/Typography";
import { TooltipTx } from "shared/components/TooltipTx";
import { useState, memo, useEffect } from "react";
import { Alias } from "shared/components";
import CardTitle from "../CardTitle";
import { L2TxLogMuiCard, MOBILE_WIDTH } from "components/TableContainer/CustomizedMui";
import { useExtractFilesFromUrl } from "hooks/useExtractFilesFromUrl";
import { ContactProps } from "../types";
import ContractTemplateDetails from "./ContractTemplateDetails";
import ContractTemplateOnchain from "./ContractTemplateOnchain";
import ContractTemplateInstances from "./ContractTemplateInstances";
import ContractTemplateClaimsTables from "./ContractTemplateClaimsTables";
import checkIssuerAlias from "utils/checkIssuerAlias";
import { useGlobalIndex } from "hooks/useGlobalIndex";
import { ContractInfoProvider } from "../ContractInfoContext";

const ContractTemplate = (props: ContactProps) => {
  const { width, data: contractTemplate } = props;
  const { contractTemplates, interpreters, contractInstances } = useGlobalIndex();

  const contractRegistryUrl = contractTemplate?.details?.pkg_mirror.url;
  const { data: files } = useExtractFilesFromUrl(contractRegistryUrl);

  const [templateAlias, setTemplateAlias] = useState<string | undefined>(contractTemplate?.alias);

  useEffect(() => {
    const templateAliasRes = checkIssuerAlias(
      contractTemplate?.details?.contract_module,
      interpreters,
      contractTemplates,
      contractInstances
    );

    if (templateAliasRes) {
      setTemplateAlias(templateAliasRes);
    }
  }, [contractTemplate?.details?.contract_module, interpreters, contractTemplates, contractInstances]);

  return (
    <ContractInfoProvider contractInfo={contractTemplate}>
      <div
        className="L2txLogCard-container"
        style={{
          marginBottom: width > MOBILE_WIDTH ? "0px" : "20px",
          marginTop: width > MOBILE_WIDTH ? "0px" : undefined,
          height: "100%",
        }}
      >
        <L2TxLogMuiCard sx={{ minWidth: 100, padding: "20px" }}>
          <CardTitle title="Coinweb Contract Template" />

          <div className="grid_container">
            {templateAlias ? (
              <div>
                <Typography className="BoldFont16" align="left">
                  Contract Module Id
                </Typography>
                <div className="L2TxId-row">
                  <Alias alias={templateAlias} />
                </div>
              </div>
            ) : (
              contractTemplate?.details?.contract_module &&
              contractTemplate?.alias && (
                <>
                  <div>
                    <Typography className="BoldFont16" align="left">
                      Contract Module Id
                    </Typography>

                    <TooltipTx isHovered value={contractTemplate?.details?.contract_module} />
                  </div>
                  <div>
                    <Typography className="BoldFont16" align="left">
                      Contract Alias
                    </Typography>
                    <div className="L2TxId-row">
                      <Typography className="BoldFont14" align="center">
                        {contractTemplate?.alias}
                      </Typography>
                    </div>
                  </div>
                </>
              )
            )}

            {contractTemplate?.details?.pkg_mirror?.url && (
              <div>
                <Typography className="BoldFont16" align="left">
                  Javascript Package
                </Typography>

                <Typography className="BoldFont14" align="center">
                  <a
                    className="BoldFont14"
                    href={`https://npm.coinweb.io/-/web/detail/cweb_${contractTemplate.details.contract_module}`}
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    Click here to open npm link
                  </a>
                </Typography>
              </div>
            )}

            {files && <ContractTemplateDetails files={files}
              contractTemplates={contractTemplates}
              interpreters={interpreters}
              contractInstances={contractInstances} />}
          </div>

          {<ContractTemplateInstances contractInfo={contractTemplate} />}

          {files && <ContractTemplateOnchain files={files} />}

          {files && <ContractTemplateClaimsTables files={files} />}
        </L2TxLogMuiCard>
      </div>
    </ContractInfoProvider>
  );
};
export default memo(ContractTemplate);
