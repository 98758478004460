import { ClaimKeyFirst, ClaimKeySecond, cweb_api, NetworkName, ClaimIssuer, CwebApi } from "@coinweb/claims-client";
import { REACT_APP_WALLET_API_ENDPOINT } from "conf";
import { useCallback, useEffect, useRef, useState } from "react";

const useClaimContractHistory = () => {
  const cwebClaimsClientRef = useRef<CwebApi | null>(null);

  useEffect(() => {
    cwebClaimsClientRef.current = cweb_api(REACT_APP_WALLET_API_ENDPOINT || "");
    return () => {
      if (cwebClaimsClientRef.current) {
        cwebClaimsClientRef.current.free();
      }
    };
  }, []);

  const fetchContractHistory = useCallback(
    async (networkName: NetworkName, contractId: string, offset: number, count: number) => {
      const cwebClaimsClient = cwebClaimsClientRef.current;
      if (!cwebClaimsClient) {
        throw new Error("cwebClaimsClient is not initialized");
      }

      try {
        // await cwebClaimsClient.update_tip();
        const shard = cwebClaimsClient.shard(networkName);
        const issuer = "TxLogProvider" as ClaimIssuer;
        const contract = shard.provider(issuer);
        const firstKey: ClaimKeyFirst = "0x" + contractId;
        const result = await contract.get_all(firstKey, offset, count);
        return result;
      } catch (error) {
        console.error("Error fetching contract history: ", error);
        throw error;
      }
    },
    []
  );

  return { fetchContractHistory };
};

export default useClaimContractHistory;
