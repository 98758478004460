import Typography from "@mui/material/Typography";
import { FileObject } from "hooks/useExtractFilesFromUrl";
import { InterpreterObjectType, TransformedTemplate, TargetInstance } from "hooks/useGlobalIndex/types";
import { getContractTemplateDetails } from "../helpers";
import { TooltipTx } from "shared/components/TooltipTx";
import { formatKey } from "utils/formatKey";

const ContractTemplateDetails = ({
  files,
  contractTemplates,
  interpreters,
  contractInstances,
}: {
  files: FileObject;
  interpreters: Record<string, InterpreterObjectType> | null;
  contractTemplates: Record<string, TransformedTemplate>;
  contractInstances: Record<string, TargetInstance>;
}) => {
  const contractDetails = getContractTemplateDetails(files);

  if (!contractDetails) {
    return null;
  }

  const { engine, description, keywords, homepage } = contractDetails;
  return (
    <>
      <div>
        <Typography className="BoldFont16" align="left">
          Description
        </Typography>
        <div className="L2TxId-row">
          <Typography className="BoldFont14" align="center">
            {description !== null ? description : "not provided"}
          </Typography>
        </div>
      </div>
      <div>
        <Typography className="BoldFont16" align="left">
          Keywords
        </Typography>
        <div className="L2TxId-row">
          <Typography className="BoldFont14" align="center">
            {keywords !== null ? keywords : "not provided"}
          </Typography>
        </div>
      </div>
      <div>
        <Typography className="BoldFont16" align="left">
          Homepage
        </Typography>
        <div className="L2TxId-row">
          <Typography className="BoldFont14" align="center">
            {homepage !== null ? (
              <a className="BoldFont14" href={homepage} target="_blank" rel="noreferrer noopener">
                {homepage}
              </a>
            ) : (
              "not provided"
            )}
          </Typography>
        </div>
      </div>
      <div>
        <Typography className="BoldFont16" align="left">
          Engine
        </Typography>
        <div className="L2TxId-row L2TxId-container">
          <Typography className="BoldFont14" align="center">
            {engine
              ? Object.keys(engine).map((key) => (
                  <Typography className="BoldFont14" align="center" sx={{ width: "max-content" }}>
                    {formatKey(key)}:{" "}
                    <span>
                      <TooltipTx value={engine[key]} />
                    </span>
                  </Typography>
                ))
              : "not provided"}
          </Typography>
        </div>
      </div>
    </>
  );
};
export default ContractTemplateDetails;
