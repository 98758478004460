import { useCallback, useEffect, useMemo, useState } from "react";
import { Data, InterpreterObjectType, OriginalContractTemplate, TransformedTemplate, TargetInstance } from "./types";
import yaml from "js-yaml";
import { Suggestions } from "utils/types";
import convertContractsToSuggestions from "utils/convertContractsToSuggestions";
import convertContractInstancesToSuggestions from "utils/convertContractInstancesToSuggestions";
import convertInterpretersToSuggestions from "utils/convertInterpretersToSuggestions";
import { GLOBAL_INDEX_URL, GLOBAL_INDEX_WINDOW_SYMBOL } from "conf";
import { cloneDeep } from "lodash";

export const useGlobalIndex = () => {
  const [contractTemplates, setContractTemplates] = useState<Record<string, TransformedTemplate>>({});
  const [suggestions, setSuggestions] = useState<Suggestions[]>([]);
  const [interpreters, setInterpreters] = useState<Record<string, InterpreterObjectType> | null>(null);
  const [contractInstances, setContractInstances] = useState<Record<string, TargetInstance>>({});

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Error>();

  useEffect(() => {
    const getGlobalIndex = async () => {
      try {
        setIsLoading(true);

        const response = await fetch(GLOBAL_INDEX_URL);
        const json = await response.json();
        const text = atob(json.content);
        const newContractInstances = cloneDeep(contractInstances); // Create a copy to avoid mutating state directly

        const parsedData = yaml.load(text) as Data;
        if (parsedData?.interpreters && parsedData?.contract_templates) {
          const data: Record<string, OriginalContractTemplate> = parsedData.contract_templates ?? {};
          const transformedData: Record<string, TransformedTemplate> = {};

          for (const key in data) {
            if (data.hasOwnProperty(key)) {
              const template = data[key];
              if (template.target_instances.length > 0) {
                template.target_instances.forEach((instance) => {
                  newContractInstances[instance.instance_id] = {
                    alias: instance.alias,
                    instance_id: instance.instance_id,
                    parameters: instance.parameters,
                    contractModuleId: template.contract_module,
                    packageURL: template.pkg_mirror.url,
                  };
                });
              }
              transformedData[template.contract_module] = {
                alias: key,
                details: template,
              };
            }
          }
          setContractInstances(newContractInstances);
          setInterpreters(parsedData.interpreters);
          setContractTemplates(transformedData);

          window[GLOBAL_INDEX_WINDOW_SYMBOL] = {
            interpreters: parsedData.interpreters,
            contractInstances: newContractInstances,
            contractTemplates: transformedData,
          };

          const interpreterSuggestions = convertInterpretersToSuggestions(parsedData.interpreters);
          const contractSuggestions = convertContractsToSuggestions(parsedData.contract_templates);
          const contractInstanceSuggestions = convertContractInstancesToSuggestions(newContractInstances);

          setSuggestions([...interpreterSuggestions, ...contractSuggestions, ...contractInstanceSuggestions]);
        }
      } catch (e) {
        setError(e as Error);
      } finally {
        setIsLoading(false);
      }
    };

    if (!suggestions.length || !interpreters) {
      void getGlobalIndex();
    }
  }, [interpreters, suggestions.length, contractInstances]);

  useEffect(() => {
    let interpreterSuggestions: Suggestions[] = [];
    if (interpreters) {
      interpreterSuggestions = convertInterpretersToSuggestions(interpreters);
    }

    const contractSuggestions = convertContractsToSuggestions(contractTemplates);
    const contractInstanceSuggestions = convertContractInstancesToSuggestions(contractInstances);
    let combinedSuggestions = [...interpreterSuggestions, ...contractSuggestions, ...contractInstanceSuggestions];
    setSuggestions(combinedSuggestions);
  }, [interpreters, contractTemplates, contractInstances]);
  const findContractInstance = useCallback(
    (searchBy: string) => {
      const query = searchBy.replace(/^0x/, "");
      if (contractInstances[query]) {
        return contractInstances[query];
      }

      const contractInstanceByAlias = Object.values(contractInstances).find((instance) => instance.alias === searchBy);
      if (contractInstanceByAlias) {
        return contractInstanceByAlias;
      }

      return null;
    },
    [contractInstances]
  );

  const findContractTemplate = useCallback(
    (searchBy: string) => {
      const query = searchBy.replace(/^0x/, "");
      if (contractTemplates[query]) {
        return contractTemplates[query];
      }

      const contractTemplateByAlias = Object.values(contractTemplates).find((template) => template.alias === searchBy);
      if (contractTemplateByAlias) {
        return contractTemplateByAlias;
      }

      return null;
    },
    [contractTemplates]
  );

  return useMemo(
    () => ({
      contractTemplates,
      suggestions,
      interpreters,
      contractInstances,
      findContractInstance,
      findContractTemplate,
      isLoading,
      setIsLoading,
      error,
    }),
    [
      contractTemplates,
      suggestions,
      interpreters,
      contractInstances,
      isLoading,
      error,
      findContractInstance,
      findContractTemplate,
    ]
  );
};
