import "./L2TxLogCard.css";
import "../TxCard/TxCard.css";
import { memo, useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { NetworkName } from "../../generated/graphql";
import DeactiveSwipe from "../../assets/Icons/icon-swipe-deactive.svg";
import ActiveSwipe from "../../assets/Icons/icon-swipe-active.svg";
import { BlockChains } from "utils/constants";
import Typography from "@mui/material/Typography";
import {
  L2TxLogMuiCard,
  L2TxLogMuiCardContent,
  L2TxLogDivider,
  MOBILE_WIDTH,
} from "components/TableContainer/CustomizedMui";
import { TooltipTx } from "shared/components/TooltipTx";
import ClaimDataOpSection from "./ClaimDataOpSection";
import { OptionClaim } from "@coinweb/claims-client";

type ClaimDataProps = {
  height: number | string;
  title: string;
  data: {
    network: NetworkName;
    claimResult: OptionClaim;
  };
  width: any;
  setNetwork: (n: NetworkName | undefined | 0) => void;
};

const ClaimDataCard = (props: ClaimDataProps) => {
  const { title, data, width, setNetwork, height } = props;
  const blockchain = BlockChains[data.network];
  const [currData, setCurrData] = useState(0);

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (data.claimResult !== null && data) {
      setNetwork(0);
    }
  }, [data, setNetwork]);

  const nextSlide = (len: number) => {
    setCurrData(currData === len - 1 ? len - 1 : currData + 1);
  };

  const prevSlide = () => {
    setCurrData(currData === 0 ? 0 : currData - 1);
  };
  return (
    <div
      className="L2txLogCard-container"
      style={{
        marginBottom: "20px",
        marginTop: width > MOBILE_WIDTH ? "0px" : undefined,
        height: "100%",
      }}
    >
      <L2TxLogMuiCard sx={{ minWidth: 100 }}>
        <L2TxLogMuiCardContent sx={{ minWidth: 100 }}>
          <div className="Center-container">
            <div
              className={width > MOBILE_WIDTH ? "Center" : "Center-mobile"}
              style={{
                position: "relative",
              }}
            >
              <Typography className="BoldFont16" align="center">
                {title}
              </Typography>
              <div className="L2TxLogCard-data-container">
                <img style={{ marginTop: "10px" }} id="Block-icon" src={blockchain.icon} alt="bc" />
                <Typography className="BoldFont16">{blockchain.hover}</Typography>
              </div>
              <Typography align="center" className="BoldFont16">
                Block Height #{height}
              </Typography>

              {/* {blockInfo?.blockHash && (
                <div className="L2TxId-row">
                  <Typography className="BoldFont16" align="left">
                    Block Hash
                  </Typography>

                  <Tooltip
                    componentsProps={{
                      tooltip: {
                        sx: {
                          width: "min-content !important",
                          backgroundColor: "#4f4f72 !important",
                          padding: "0px !important",
                          marginTop: "-1px !important",
                        },
                      },
                    }}
                    disableFocusListener
                    TransitionComponent={Fade}
                    title={<div id="TxId-tooltip">{blockInfo?.blockHash}</div>}
                  >
                    <Typography
                      className="BoldFont14 L2TxId-container"
                      align="center"
                      sx={{ alignContent: "center", marginLeft: "5px" }}
                      onClick={() => {
                        const newSearchParams = new URLSearchParams(searchParams.toString());
                        newSearchParams.set("search", blockInfo?.blockHash);
                        setSearchParams(newSearchParams);
                      }}
                    >
                      {" "}
                      {blockInfo?.blockHash ? handleTxIds(blockInfo?.blockHash) : "N/A"}
                    </Typography>
                  </Tooltip>
                </div>
              )} */}

              {data?.claimResult?.body &&
                typeof data.claimResult.body === "object" &&
                "block_id" in data.claimResult.body && (
                  <div className="L2TxId-row">
                    <Typography className="BoldFont16" align="left">
                      Block ID
                    </Typography>

                    {data?.claimResult?.body?.block_id ? (
                      <TooltipTx value={data?.claimResult?.body?.block_id as string} />
                    ) : (
                      "N/A"
                    )}
                  </div>
                )}
              {data?.claimResult?.fees_stored && (
                <div className="L2TxId-row">
                  <Typography className="BoldFont16" align="left">
                    Stored Fees
                  </Typography>
                  <Typography
                    className="BoldFont14 L2TxId-container"
                    align="center"
                    sx={{ alignContent: "center", marginLeft: "5px" }}
                  >
                    {parseInt(data?.claimResult?.fees_stored.toString(), 16)}
                  </Typography>
                </div>
              )}
            </div>
          </div>
          {data.claimResult !== null &&
            data?.claimResult?.body &&
            typeof data.claimResult.body === "object" &&
            "l2_txs" in data.claimResult.body &&
            Array.isArray(data.claimResult.body.l2_txs) &&
            data.claimResult.body.l2_txs.length > 0 && (
              <>
                <L2TxLogDivider variant="middle" />
                <div id="Carousel" style={{ textIndent: "0px !important" }}>
                  <div style={{ width: "100%", height: "100%" }}>
                    <div className="Carousel-inner" style={{ height: "100%" }}>
                      <div className="Carousel-icon-container" onClick={prevSlide}>
                        <div className="Carousel-icon">
                          <img src={currData === 0 ? DeactiveSwipe : ActiveSwipe} alt="swipe"></img>
                        </div>
                      </div>

                      <div className="Center-container">
                        <div className={width > 425 ? "Center" : "Center-mobile"}>
                          <div>
                            {data.claimResult.body.l2_txs[currData]?.cweb_txid && (
                              <div className="L2TxId-row">
                                <Typography className="BoldFont16" align="left">
                                  CWEB TxId
                                </Typography>
                                <TooltipTx value={data.claimResult.body.l2_txs[currData].cweb_txid} />
                              </div>
                            )}
                            {data.claimResult.body.l2_txs[currData]?.cweb_tx?.content?.ops && (
                              <ClaimDataOpSection data={data.claimResult.body.l2_txs[currData].cweb_tx.content.ops} />
                            )}
                          </div>
                        </div>
                      </div>
                      <div
                        className="Carousel-icon-container"
                        onClick={() => nextSlide((data.claimResult!.body as { l2_txs: any[] }).l2_txs.length)}
                      >
                        <div className="Carousel-icon">
                          <img
                            className="Right-icon-img"
                            src={
                              currData === (data.claimResult!.body as { l2_txs: any[] }).l2_txs.length - 1
                                ? DeactiveSwipe
                                : ActiveSwipe
                            }
                            alt="swipe"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
        </L2TxLogMuiCardContent>
      </L2TxLogMuiCard>
    </div>
  );
};
export default memo(ClaimDataCard);
