import "./L2TxLogCard.css";
import { memo } from "react";
import Typography from "@mui/material/Typography";
import { useSearchParams } from "react-router-dom";
import { TxLoadedGql, ContractGql } from "../../generated/graphql";
import L1EmbeddedParentSection from "./L1EmbeddedParentSection";
import ActiveSwipe from "../../assets/Icons/icon-swipe-active.svg";
import { MOBILE_WIDTH } from "components/TableContainer/CustomizedMui";
import { TooltipTx } from "shared/components/TooltipTx";

type TxLoadedSectionProps = {
  data: TxLoadedGql;
  width: any;
  networkName: string;
};

const TxLoadedSection = (props: TxLoadedSectionProps) => {
  const { data, width } = props;

  const [searchParams, setSearchParams] = useSearchParams();
  return (
    <div
      className="L2txLogCardInfo-container"
      style={{
        marginBottom: width > MOBILE_WIDTH ? "0px" : "20px",
        marginTop: width > MOBILE_WIDTH ? "0px" : undefined,
        height: "100%",
      }}
    >
      <div className="relativeCon ">
        {(data?.parents as ContractGql)?.parents &&
          (data?.parents as ContractGql)?.parents[0] &&
          (data?.parents as ContractGql)?.parents[0]?.l2Txid && (
            <div
              className="ParentsCarousel-icon"
              style={{
                display: "flex",
                justifyContent: "left",
                textAlignLast: "left",
              }}
            >
              <img className="linkArrow" src={ActiveSwipe} alt="swipe" />

              <Typography
                className="TxLogFontBold14 linkHover"
                sx={{ top: "-15px", width: "auto !important" }}
                onClick={() => {
                  const newSearchParams = new URLSearchParams(searchParams.toString());
                  newSearchParams.set("search", (data.parents as ContractGql).parents[0].l2Txid);
                  setSearchParams(newSearchParams);
                }}
              >
                Parent Tx
              </Typography>
              <div className="L2TxId-row" style={{ marginLeft: "10px", display: "flex" }}>
                <TooltipTx value={(data.parents as ContractGql).parents[0].l2Txid} />
              </div>
            </div>
          )}
      </div>

      {data.parents.__typename === "EmbeddedGql" && <L1EmbeddedParentSection data={data.parents} width={width} />}
    </div>
  );
};
export default memo(TxLoadedSection);
