import "./styles.css";
import Typography from "@mui/material/Typography";
import CopyIcon from "components/CopyIcon";
import { memo } from "react";
import CardTitle from "./CardTitle";
import {
  L2TxLogMuiCard,
  MOBILE_WIDTH,
} from "components/TableContainer/CustomizedMui";
import { InterpreterObjectType } from "hooks/useGlobalIndex";
import {TooltipTx}  from "shared/components/TooltipTx";

type InterpretProps = {
  width: number;
  data: InterpreterObjectType | undefined;
  id: string;
};

const Interpreter = (props: InterpretProps) => {
  const { width, data, id } = props;

  return (
    <div
      className="L2txLogCard-container"
      style={{
        marginBottom: width > MOBILE_WIDTH ? "0px" : "20px",
        marginTop: width > MOBILE_WIDTH ? "0px" : undefined,
        height: "100%",
      }}
    >
      <L2TxLogMuiCard sx={{ minWidth: 100, padding: "20px" }}>
        <CardTitle title="Coinweb Interpreter" />
        <div
          style={{
            display: width > MOBILE_WIDTH ? "grid" : "block",
            gridTemplateColumns: "1fr 1fr",
          }}
        >
          <div>
            <Typography className="BoldFont16" align="left">
              Interpreter Id
            </Typography>
            <div className="L2TxId-row">
              {id ? (
             <TooltipTx isHovered value={id} />
              ) : (
                "N/A"
              )}
            </div>
          </div>
          <div>
            <Typography className="BoldFont16" align="left">
              Interpreter Alias
            </Typography>
            <div className="L2TxId-row">
              {data?.alias ? (
                <>
                  <Typography className="BoldFont14" align="center">
                    {data.alias}
                  </Typography>
                  <CopyIcon data={data.alias} />
                </>
              ) : (
                "N/A"
              )}
            </div>
          </div>
        </div>
        {data?.entry_point?.url && (
          <div style={{ marginTop: "10px" }}>
            <Typography className="BoldFont14" align="center">
              <a className="BoldFont14" href={data?.entry_point?.url}>
                Click here to download the entry points
              </a>
            </Typography>
          </div>
        )}
      </L2TxLogMuiCard>
    </div>
  );
};
export default memo(Interpreter);
