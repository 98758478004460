import React from "react";
import { Tooltip, Typography, Fade } from "@mui/material";
import CopyIcon from "components/CopyIcon";
import handleTxIds from "utils/handleTxIds";
import { useSearchParams } from "react-router-dom";

type TooltipTxProps = {
  value: string;
  isHovered?: boolean;
  sx?: React.CSSProperties;
};

const TooltipTx: React.FC<TooltipTxProps> = ({ value, isHovered, sx }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  return (
    <div className="L2TxId-row" style={sx}>
      <Tooltip
        componentsProps={{
          tooltip: {
            sx: {
              width: "min-content !important",
              backgroundColor: "#4f4f72 !important",
              padding: "0px !important",
              marginTop: "-1px !important",
            },
          },
        }}
        disableFocusListener
        TransitionComponent={Fade}
        title={<div id="TxId-tooltip">{value}</div>}
      >
        <div
          className="L2TxId-container"
          onClick={() => {
            const newSearchParams = new URLSearchParams(searchParams.toString());
            newSearchParams.set("search", value);
            setSearchParams(newSearchParams);
          }}
        >
          <Typography
            className={isHovered ? "BoldFont14Hover" : "BoldFont14"}
            align="center"
            sx={{ marginLeft: "3px" }}
          >
            {handleTxIds ? handleTxIds(value) : value}
          </Typography>
        </div>
      </Tooltip>
      <CopyIcon data={value} />
    </div>
  );
};

export default TooltipTx;
