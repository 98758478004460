import "../styles.css";
import { useState, useEffect } from "react";
import Grid from "@mui/material/Grid2";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Typography,
  Paper,
  Collapse,
  IconButton,
  Button,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { TargetInstance } from "hooks/useGlobalIndex/types";
import useClaimContractHistory from "hooks/useClaimContractHistory";
import useChainQueryParameters from "hooks/useChainQueryParameters";
import { Alias } from "shared/components";
import checkIssuerAlias from "utils/checkIssuerAlias";
import { TooltipTx } from "shared/components/TooltipTx";
import { NetworkName, type Claim } from "@coinweb/claims-client";
import { useGlobalIndex } from "hooks/useGlobalIndex";
import LoadingBox from "components/LoadingBox";

type ContactHistoryProps = {
  instance: TargetInstance;
};

const ContractHistoryTables = (props: ContactHistoryProps) => {
  const { instance } = props;

  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(5);
  const [totalItems, setTotalItems] = useState<number>(0); // Track total items from backend
  const [isLoading, setIsLoading] = useState<boolean>(false); // Loading state
  const [error, setError] = useState<string | null>(null); // Error state
  const { contractTemplates, interpreters, contractInstances } = useGlobalIndex();
  const [historyData, setHistoryData] = useState<Claim[]>([]);
  const chain = useChainQueryParameters();

  const { fetchContractHistory } = useClaimContractHistory();
  const fetchHistory = async (newOffset: number, limit: number) => {
    setIsLoading(true);
    setError(null);
    try {
      const data = await fetchContractHistory(
        NetworkName[chain.clientNetworkName],
        instance.instance_id,
        newOffset,
        limit
      );

      if (data && data.length > 0) {
        setHistoryData((prev) => [...prev, ...data]);
        setTotalItems((prevTotal) => Math.max(prevTotal, newOffset + data.length));
      } else {
        setError(
          "No data found. Either select a different shard network from the list above, or there are currently no recorded contract history entries for this instance."
        );
      }
    } catch (fetchError) {
      console.error("Error fetching contract history:", fetchError);
      setError("Failed to fetch data. Please try again later.");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isDropdownOpen) {
      fetchHistory(0, rowsPerPage);
    }
  }, [isDropdownOpen]);

  const handleIssuerAliance = (issuer: string) => {
    const issuerRes = checkIssuerAlias(issuer, interpreters, contractTemplates, contractInstances);
    return issuerRes;
  };

  const handleLoadMore = () => {
    const newOffset = historyData.length;
    fetchHistory(newOffset, rowsPerPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0);
    setHistoryData([]);
    fetchHistory(0, newRowsPerPage);
    setTotalItems(newRowsPerPage);
  };

  const handleChangePage = (_: unknown, newPage: number) => {
    setPage(newPage);
  };

  return (
    <Grid container>
      <Grid size={{ xs: 12 }} sx={{ display: "flex", justifyContent: "center", alignItems: "center", margin: "auto" }}>
        <div
          style={{ cursor: "pointer", display: "flex", justifyContent: "center", alignItems: "center" }}
          onClick={() => setIsDropdownOpen(!isDropdownOpen)}
        >
          <IconButton aria-label="expand row" size="small">
            {isDropdownOpen ? (
              <KeyboardArrowUpIcon sx={{ color: "rgb(0, 195, 255)" }} />
            ) : (
              <KeyboardArrowDownIcon sx={{ color: "rgb(0, 195, 255)" }} />
            )}
          </IconButton>
          <Typography className="BlueFont14">Contract Instance History</Typography>
        </div>
      </Grid>
      <Grid size={{ xs: 12 }}>
        <Collapse
          sx={{
            overflow: "hidden",
            borderRadius: "6px",
            backgroundColor: "#20214a",
            marginTop: "10px",
          }}
          in={isDropdownOpen}
          timeout="auto"
          unmountOnExit
        >
          {isLoading ? (
            <LoadingBox>Loading...</LoadingBox>
          ) : error ? (
            <Typography sx={{ color: "#ff0000", textAlign: "center", padding: "20px" }}>{error}</Typography>
          ) : (
            <>
              <TableContainer component={Paper} sx={{ backgroundColor: "#1b1e3e" }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ color: "#ffffff" }}>Transaction ID (TXID)</TableCell>
                      <TableCell sx={{ color: "#ffffff" }}>Issuer</TableCell>
                      <TableCell sx={{ color: "#ffffff" }}>Execution Step</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {historyData &&
                      historyData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                        return (
                          <TableRow key={index}>
                            {Array.isArray(row.key.second_part) && row.key.second_part.length > 0 ? (
                              <>
                                {row.key.second_part[0] ? (
                                  <TableCell key={0} sx={{ color: "#ffffff", textalign: "left" }}>
                                    {<TooltipTx value={row.key.second_part[0]} sx={{ marginLeft: "0px" }} />}
                                  </TableCell>
                                ) : (
                                  "N/A"
                                )}
                                {row.key.second_part[1] && (
                                  <TableCell key={1} sx={{ color: "#ffffff" }}>
                                    {typeof row.key.second_part[1] === "object" && row.key.second_part[1] !== null ? (
                                      <>
                                        {Object.entries(row.key.second_part[1]).map(([key, value], idx) => {
                                          const alias = handleIssuerAliance(String(value));
                                          return (
                                            <div key={idx}>
                                              <strong>{key}:</strong>
                                              {value ? alias ? <Alias alias={alias} /> : value : "N/A"}
                                            </div>
                                          );
                                        })}
                                      </>
                                    ) : (
                                      "N/A"
                                    )}
                                  </TableCell>
                                )}
                                {row.key.second_part[2] && (
                                  <TableCell key={2} sx={{ color: "#ffffff" }}>
                                    {row.key.second_part[2] ?? "N/A"}
                                  </TableCell>
                                )}
                              </>
                            ) : (
                              <TableCell colSpan={3} sx={{ color: "#ffffff", textAlign: "center" }}>
                                No Data Available
                              </TableCell>
                            )}
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "10px",
                    flexWrap: "wrap",
                  }}
                >
                  <div
                    style={{
                      textAlign: "left",
                      minWidth: "300px",
                      marginBottom: "10px",
                      width: "-webkit-fill-available",
                      justifyItems: "flex-start",
                    }}
                  >
                    <TablePagination
                      component="div"
                      count={totalItems}
                      page={page}
                      onPageChange={handleChangePage}
                      rowsPerPage={rowsPerPage}
                      rowsPerPageOptions={[5, 10, 20]}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      sx={{
                        color: "#ffffff",
                        "& .MuiPaginationItem-root": {
                          color: "#ffffff",
                        },
                        "& .Mui-disabled": {
                          color: "#838383 !important",
                        },
                        "& .MuiSvgIcon-root": {
                          fill: "#ffffff",
                        },
                        "& .Mui-disabled .MuiSvgIcon-root": {
                          fill: "#838383 !important",
                        },
                      }}
                    />
                  </div>
                  <div style={{ textAlign: "center", minWidth: "300px", width: "-webkit-fill-available" }}>
                    <Button variant="contained" color="primary" onClick={handleLoadMore} sx={{ marginTop: "0px" }}>
                      Load More
                    </Button>
                  </div>
                </div>
              </TableContainer>
            </>
          )}
        </Collapse>
      </Grid>
    </Grid>
  );
};
export default ContractHistoryTables;
