import "../styles.css";
import { Table, TableBody } from "@mui/material";
import { TargetInstance } from "hooks/useGlobalIndex/types";
import CardTitle from "../CardTitle";
import { L2TxLogMuiCard, MOBILE_WIDTH } from "components/TableContainer/CustomizedMui";
import { useExtractFilesFromUrl } from "hooks/useExtractFilesFromUrl";
import ContractInstancesParameters from "./ContractInstanceParameters";
import ContractInstanceDetails from "./ContractInstanceDetails";
import ContractInstanceClaimsTables from "./ContractInstanceClaimsTables";
import ContractHistoryTables from "./ContractHistoryTables";
import { ContractInfoProvider } from "../ContractInfoContext";

type ContactProps = {
  width: number;
  data: TargetInstance;
};

const ContractInstances = (props: ContactProps) => {
  const { width, data: contractInstance } = props;
  const { data: files } = useExtractFilesFromUrl(contractInstance?.packageURL);

  if (!contractInstance) {
    return null;
  }
  return (
    <ContractInfoProvider contractInfo={contractInstance}>
      <div
        className="L2txLogCard-container"
        style={{
          marginBottom: width > MOBILE_WIDTH ? "0px" : "20px",
          marginTop: width > MOBILE_WIDTH ? "0px" : undefined,
          height: "100%",
        }}
      >
        <L2TxLogMuiCard sx={{ minWidth: 100, padding: "20px" }}>
          <CardTitle title="Coinweb Contract Instance" />
          <ContractInstanceDetails instance={contractInstance} />

          <Table>
            <TableBody>
              <ContractInstancesParameters parameters={contractInstance.parameters} />
            </TableBody>
          </Table>
          <ContractHistoryTables instance={contractInstance} />

          {files && <ContractInstanceClaimsTables files={files} />}
        </L2TxLogMuiCard>
      </div>
    </ContractInfoProvider>
  );
};
export default ContractInstances;
